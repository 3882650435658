import Vue from 'vue'
import Router from 'vue-router'

import config from './config/config'
import Auth from './views/Auth.vue'

const routesBase = [
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "articles" */ './views/Articles.vue')
  },
  {
    path: '/case/:id',
    name: 'Case',
    component: () => import(/* webpackChunkName: "case" */ './views/cases/Case.vue')
  },
  {
    path: '/cases',
    name: 'CasesLayout',
    component: () => import(/* webpackChunkName: "cases" */ './views/cases/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Cases',
        component: () => import(/* webpackChunkName: "appReportingLoading" */ './views/cases/Cases.vue')
      },
      {
        path: 'datastrat',
        name: 'CasesDataStrats',
        component: () => import(/* webpackChunkName: "appReportingMain" */ './views/cases/DataStrats.vue')
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ './views/Contact.vue')
  },
  {
    path: '/covid/:page?/:id?',
    name: 'Covid',
    component: () => import(/* webpackChunkName: "covid" */ './views/Covid.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ './views/Events.vue')
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: () => import(/* webpackChunkName: "events" */ './views/Event.vue')
  },
  {
    path: '/extsource/:id',
    name: 'Extsource',
    component: () => import(/* webpackChunkName: "extsource" */ './views/Extsource.vue')
  },
  {
    path: '/extsources',
    name: 'Extsources',
    component: () => import(/* webpackChunkName: "extsources" */ './views/Extsources.vue')
  },
  {
    path: '/impact/initiative/:id',
    name: 'ImpactInitiative',
    component: () => import(/* webpackChunkName: "impactinitiative" */ './views/impact/Initiative.vue')
  },
  {
    path: '/impact/initiative/:id/log',
    name: 'ImpactInitiativeLog',
    component: () => import(/* webpackChunkName: "impactinitiativelog" */ './views/impact/InitiativeLog.vue')
  },
  {
    path: '/impact/initiatives',
    name: 'ImpactInitiatives',
    component: () => import(/* webpackChunkName: "impactinitiatives" */ './views/impact/Initiatives.vue')
  },
  {
    path: '/impact/policies',
    component: () => import(/* webpackChunkName: "impactpolicies" */ './views/impact/Policies.vue'),
    children: [
      {
        path: 'download',
        component: () => import(/* webpackChunkName: "impactpoliciesdownload" */ './views/impact/policies/Download.vue'),
        children: [
          {
            path: 'choose-data',
            name: 'ImpactPoliciesDownload',
            component: () => import(/* webpackChunkName: "impactpolicieschoosedata" */ './views/impact/policies/download/Data.vue')
          },
          {
            path: 'choose-template',
            component: () => import(/* webpackChunkName: "impactpolicieschoosetemplate" */ './views/impact/policies/download/Template.vue'),
            children: [
              {
                path: 'basic',
                component: () => import(/* webpackChunkName: "impactpoliciestemplatebasic" */ './views/impact/policies/download/templates/TemplateBasic.vue')
              },
              {
                path: 'grouped',
                component: () => import(/* webpackChunkName: "impactpoliciestemplategrouped" */ './views/impact/policies/download/templates/TemplateGrouped.vue')
              },
              {
                path: 'table',
                component: () => import(/* webpackChunkName: "impactpoliciestemplatetable" */ './views/impact/policies/download/templates/Table.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'home',
        name: 'CPRD',
        component: () => import(/* webpackChunkName: "impactpolicieshome" */ './views/impact/policies/Home.vue')
      },
      {
        path: 'help',
        name: 'CPRDHelp',
        component: () => import(/* webpackChunkName: "impactpolicieshome" */ './views/impact/policies/Help.vue')
      },
      {
        path: 'news',
        name: 'ImpactPoliciesNews',
        component: () => import(/* webpackChunkName: "impactpoliciesnews" */ './views/impact/policies/News.vue')
      },
      {
        path: 'library/:id?',
        name: 'ImpactPolicies',
        component: () => import(/* webpackChunkName: "impactpolicieslist" */ './views/impact/policies/PoliciesList.vue')
      },
      {
        path: '/impact/policy/:id',
        name: 'ImpactPoliciesPolicy',
        component: () => import(/* webpackChunkName: "impactpoliciespolicy" */ './views/impact/Policy.vue')
      },
      {
        path: '',
        name: 'ImpactPoliciesDashboard',
        component: () => import(/* webpackChunkName: "impactpoliciesdashboard" */ './views/impact/policies/PoliciesDashboard.vue')
      },
      {
        path: 'table',
        name: 'ImpactPoliciesTable',
        component: () => import(/* webpackChunkName: "impactpoliciestable" */ './views/impact/policies/PoliciesTable.vue')
      },
      {
        path: 'market/:country?/:initiative?',
        name: 'ImpactPoliciesCountry',
        component: () => import(/* webpackChunkName: "impactpoliciescountry" */ './views/impact/policies/CountryPolicies.vue')
      },
      {
        path: 'country/:country1?/:country2?',
        name: 'ImpactPoliciesCountryComparison',
        component: () => import(/* webpackChunkName: "impactpoliciescountrycomparison" */ './views/impact/policies/CountryPoliciesComparison.vue')
      },
      {
        path: 'datastrats',
        name: 'ImpactPoliciesDataStrats',
        component: () => import(/* webpackChunkName: "impactpoliciesdatastrats" */ './views/impact/policies/DataStrats.vue')
      },
      {
        path: 'initiative/:id',
        name: 'ImpactPoliciesInitiative',
        component: () => import(/* webpackChunkName: "impactpoliciesinitiative" */ './views/impact/Initiative.vue')
      }
    ]
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import(/* webpackChunkName: "Lega" */ './views/Legal.vue')
  },
  {
    path: '/ip',
    name: 'Ip',
    component: () => import(/* webpackChunkName: "ip" */ './views/Ip.vue')
  },
  {
    path: '/mardashboard',
    name: 'MarDashboard',
    component: () => import(/* webpackChunkName: "mardashboard" */ './views/MarDashboard.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ './views/News.vue')
  },
  {
    path: '/newsadvancedfilter',
    name: 'NewsAdvancedFilter',
    component: () => import(/* webpackChunkName: "newsadvancedfilter" */ './views/NewsAdvancedFilter.vue')
  },
  {
    path: '/newscategory/:category',
    name: 'NewsCategory',
    component: () => import(/* webpackChunkName: "newscategory" */ './views/NewsCategory.vue')
  },
  {
    path: '/newsitem/:id',
    name: 'NewsItem',
    component: () => import(/* webpackChunkName: "newsitem" */ './views/NewsItem.vue')
  },
  {
    path: '/newssectors/:sector',
    name: 'NewsSectors',
    component: () => import(/* webpackChunkName: "newssectors" */ './views/NewsSectors.vue')
  },
  {
    path: '/news/tag/:id', // this is for backward compatibility before tags changed name to types
    component: () => import(/* webpackChunkName: "newstag" */ './views/NewsTag.vue')
  },
  {
    path: '/news/type/:id',
    name: 'NewsTag',
    component: () => import(/* webpackChunkName: "newstag" */ './views/NewsTag.vue')
  },
  {
    path: '/regbite/:id',
    name: 'Regbite',
    component: () => import(/* webpackChunkName: "regbite" */ './views/Regbite.vue')
  },
  {
    path: '/regbites',
    name: 'Regbites',
    component: () => import(/* webpackChunkName: "regbites" */ './views/Regbites.vue')
  },
  {
    path: '/regulator/:code',
    name: 'Regulator',
    component: () => import(/* webpackChunkName: "regulator" */ './views/Regulator.vue')
  },
  {
    path: '/regulators',
    name: 'Regulators',
    component: () => import(/* webpackChunkName: "regulators" */ './views/Regulators.vue')
  },
  {
    path: '/regulator2/:id',
    name: 'Regulator2',
    component: () => import(/* webpackChunkName: "regulator2" */ './views/decomp/Regulator.vue')
  },
  {
    path: '/regulators2',
    name: 'Regulators2',
    component: () => import(/* webpackChunkName: "regulators2" */ './views/decomp/Regulators.vue')
  },
  {
    path: '/search/:q',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ './views/Search.vue')
  },
  {
    path: '/signals',
    name: 'Signals',
    component: () => import(/* webpackChunkName: "signals" */ './views/Signals.vue')
  },
  {
    path: '/snapshot',
    name: 'Snapshot',
    component: () => import(/* webpackChunkName: "snapshot" */ './views/Snapshot.vue')
  },
  {
    path: '/sources',
    name: 'Sources',
    component: () => import(/* webpackChunkName: "sources" */ './views/Sources.vue')
  },
  {
    path: '/RecentActivity',
    name: 'RecentActivity',
    component: () => import(/* webpackChunkName: "RecentActivity" */ './views/RecentActivity.vue')
  },
  {
    path: '/risk/:id',
    name: 'Risk',
    component: () => import(/* webpackChunkName: "risk" */ './views/Risk.vue')
  },
  {
    path: '/risks',
    name: 'Risks',
    component: () => import(/* webpackChunkName: "risks" */ './views/Risks.vue')
  },
  {
    path: '/topic/:id',
    name: 'Topic',
    component: () => import(/* webpackChunkName: "topic" */ './views/Topic.vue')
  },
  {
    path: '/topics',
    name: 'Topics',
    component: () => import(/* webpackChunkName: "topics" */ './views/Topics.vue')
  },
  {
    path: '/topics-personalise',
    name: 'TopicsPersonalise',
    component: () => import(/* webpackChunkName: "topicspersonalise" */ './views/TopicsPersonalise.vue')
  },
  {
    path: '/types',
    name: 'Types',
    component: () => import(/* webpackChunkName: "types" */ './views/Types.vue')
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import(/* webpackChunkName: "start" */ './views/Start.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "start" */ './views/Home.vue')
  }
]

const routesExternal = [
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "settings" */ './views/About.vue')
  },
  {
    path: '/DigitalGateway/:id',
    name: 'DigitalGateway',
    component: () => import(/* webpackChunkName: "digitalgateway" */ './views/DigitalGateway.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue')
  }
]

const routesInternal = [
  {
    path: '/account/add',
    name: 'AccountAdd',
    component: () => import(/* webpackChunkName: "accountadd" */ './views/accounts/AccountForm.vue')
  },
  {
    path: '/account/edit/:id',
    name: 'AccountEdit',
    component: () => import(/* webpackChunkName: "accountadd" */ './views/accounts/AccountForm.vue')
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ './views/accounts/Account.vue')
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import(/* webpackChunkName: "accounts" */ './views/accounts/Accounts.vue')
  },
  {
    path: '/admin/autotags/stats',
    name: 'AutotagsStats',
    component: () => import(/* webpackChunkName: "autotagsstats" */ './views/admin/AutoTags.vue')
  },
  {
    path: '/admin/links',
    name: 'LinksAdmin',
    component: () => import(/* webpackChunkName: "linksadmin" */ './views/admin/Links.vue')
  },
  {
    path: '/admin/news/anytag',
    name: 'NewsAnyTag',
    component: () => import(/* webpackChunkName: "newsanytag" */ './views/admin/NewsAnyTag.vue')
  },
  {
    path: '/admin/news/count',
    name: 'NewsCount',
    component: () => import(/* webpackChunkName: "newscount" */ './views/admin/NewsCount.vue')
  },
  {
    path: '/admin/news/matrix',
    name: 'NewsMatrix',
    component: () => import(/* webpackChunkName: "newsmatrix" */ './views/admin/NewsMatrix.vue')
  },
  {
    path: '/admin/news/source',
    name: 'NewsSource',
    component: () => import(/* webpackChunkName: "newssource" */ './views/admin/NewsSource.vue')
  },
  {
    path: '/admin/notifications',
    name: 'AdminNotifications',
    component: () => import(/* webpackChunkName: "adminnotifications" */ './views/admin/Notifications.vue')
  },
  {
    path: '/admin/preferences',
    name: 'PreferencesAdmin',
    component: () => import(/* webpackChunkName: "newsmatrix" */ './views/admin/Preferences.vue')
  },
  {
    path: '/admin/recent-activity',
    name: 'AdminRecentActivity',
    component: () => import(/* webpackChunkName: "adminrecentactivity" */ './views/admin/RecentActivity.vue')
  },
  {
    path: '/admin/sources/table',
    name: 'SourcesTable',
    component: () => import(/* webpackChunkName: "sourcestable" */ './views/admin/sources/Layout.vue'),
    children: [
      {
        path: '',
        name: 'SourcesTableRows',
        component: () => import(/* webpackChunkName: "sourcestablerows" */ './views/admin/sources/Rows.vue')
      },
      {
        path: 'datastrat',
        name: 'SourcesTableDataStrats',
        component: () => import(/* webpackChunkName: "sourcestabledatastrats" */ './views/admin/sources/DataStrats.vue')
      }
    ]
  },
  {
    path: '/admin/tables',
    name: 'AdminTables',
    component: () => import(/* webpackChunkName: "admintables" */ './views/admin/Tables.vue')
  },
  {
    path: '/admin/tags',
    name: 'AdminTags',
    component: () => import(/* webpackChunkName: "admintags" */ './views/admin/Tags.vue')
  },
  {
    path: '/admin/topics/deleted',
    name: 'TopicsDeleted',
    component: () => import(/* webpackChunkName: "deletedtopics" */ './views/admin/DeletedTopics.vue')
  },
  {
    path: '/ai',
    component: () => import(/* webpackChunkName: "tahlayout" */ './views/ai/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Ai',
        component: () => import(/* webpackChunkName: "ai" */ './views/ai/Home.vue')
      },
      {
        path: 'country/:country',
        name: 'AiCountry',
        component: () => import(/* webpackChunkName: "aicountry" */ './views/ai/Country.vue')
      },
      {
        path: 'datastrats',
        name: 'AiDataStrats',
        component: () => import(/* webpackChunkName: "aidatastrats" */ './views/ai/DataStrats.vue')
      },
      {
        path: 'initiative/:id',
        name: 'AiInitiative',
        component: () => import(/* webpackChunkName: "aiinitiative" */ './views/impact/Initiative.vue')
      },
      {
        path: '/insight/:id',
        name: 'AiInsight',
        component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
      },
      {
        path: 'insights',
        name: 'AiInsights',
        component: () => import(/* webpackChunkName: "aiinsights" */ './views/ai/Insights.vue')
      },
      {
        path: 'map',
        name: 'AiMap',
        component: () => import(/* webpackChunkName: "aimap" */ './views/ai/Map.vue')
      },
      {
        path: 'news/:id',
        name: 'AiNews',
        component: () => import(/* webpackChunkName: "topic" */ './views/Topic.vue')
      },
      {
        path: '/newsitem/:id',
        name: 'AiNewsItem',
        component: () => import(/* webpackChunkName: "newsitem" */ './views/NewsItem.vue')
      },
      {
        path: 'regulations',
        name: 'AiRegulations',
        component: () => import(/* webpackChunkName: "airegulations" */ './views/ai/Regulations.vue')
      }
    ]
  },
  {
    path: '/alert/add',
    name: 'AlertAdd',
    component: () => import(/* webpackChunkName: "alertadd" */ './views/AlertAdd.vue')
  },
  {
    path: '/alert/edit/:id',
    name: 'AlertEdit',
    component: () => import(/* webpackChunkName: "alertedit" */ './views/AlertEdit.vue')
  },
  {
    path: '/alert/:id',
    name: 'Alert',
    component: () => import(/* webpackChunkName: "alert" */ './views/Alert.vue')
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import(/* webpackChunkName: "alerts" */ './views/Alerts.vue')
  },
  {
    path: '/apps/analytics',
    component: () => import(/* webpackChunkName: "appAnalyticsLayout" */ './views/apps/analytics/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppAnalytics',
        component: () => import(/* webpackChunkName: "appAnalytics" */ './views/apps/analytics/Loading.vue')
      },
      {
        path: 'start',
        name: 'AppAnalyticsStart',
        component: () => import(/* webpackChunkName: "appAnalyticsStart" */ './views/apps/analytics/Start.vue')
      },
      {
        path: 'bestexecution',
        name: 'AppAnalyticsBestExecution',
        component: () => import(/* webpackChunkName: "appAnalyticsBestExecution" */ './views/apps/analytics/BestExecution.vue')
      }
    ]
  },
  {
    path: '/apps/import',
    component: () => import(/* webpackChunkName: "appImportLayout" */ './views/apps/import/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppImport',
        component: () => import(/* webpackChunkName: "appImportLoading" */ './views/apps/import/Loading.vue')
      }
    ]
  },
  {
    path: '/apps/importing',
    component: () => import(/* webpackChunkName: "appImportingLayout" */ './views/apps/importing/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppImporting',
        component: () => import(/* webpackChunkName: "appImportingLoading" */ './views/apps/importing/Loading.vue')
      },
      {
        path: 'main',
        name: 'AppImportingMain',
        component: () => import(/* webpackChunkName: "appImportingMain" */ './views/apps/importing/Main.vue')
      }
    ]
  },
  {
    path: '/apps/obligations',
    component: () => import(/* webpackChunkName: "appObligationsLayout" */ './views/apps/obligations/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppObligations',
        component: () => import(/* webpackChunkName: "appObligationsLoading" */ './views/apps/obligations/Loading.vue')
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "appObligationsMain" */ './views/apps/obligations/Main.vue'),
        children: [
          {
            path: 'start',
            name: 'AppObligationsStart',
            component: () => import(/* webpackChunkName: "appObligationsStart" */ './views/apps/obligations/main/Start.vue')
          },
          {
            path: 'dashboard',
            name: 'AppObligationsDashboard',
            component: () => import(/* webpackChunkName: "appObligationsDashboard" */ './views/apps/obligations/main/Dashboard.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/apps/reporting',
    component: () => import(/* webpackChunkName: "appReportingLayout" */ './views/apps/reporting/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppReporting',
        component: () => import(/* webpackChunkName: "appReportingLoading" */ './views/apps/reporting/Loading.vue')
      },
      {
        path: 'main',
        name: 'AppReportingMain',
        component: () => import(/* webpackChunkName: "appReportingMain" */ './views/apps/reporting/Main.vue')
      }
    ]
  },
  {
    path: '/apps/reporting-zebra',
    component: () => import(/* webpackChunkName: "appReportingLayoutZebra" */ './views/apps/reporting-zebra/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppReportingZebra',
        component: () => import(/* webpackChunkName: "appReportingLoadingZebra" */ './views/apps/reporting-zebra/Loading.vue')
      },
      {
        path: 'main',
        name: 'AppReportingMainZebra',
        component: () => import(/* webpackChunkName: "appReportingMainZebra" */ './views/apps/reporting-zebra/Main.vue')
      }
    ]
  },
  {
    path: '/apps/:appid',
    component: () => import(/* webpackChunkName: "appUtLayout" */ './views/apps/ut/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppUT',
        component: () => import(/* webpackChunkName: "appUtStart" */ './views/apps/ut/Start.vue')
      },
      {
        path: 'controls',
        name: 'AppUTControls',
        component: () => import(/* webpackChunkName: "appUtControls" */ './views/apps/ut/Controls.vue')
      },
      {
        path: 'control/:id',
        name: 'AppUTControl',
        component: () => import(/* webpackChunkName: "appUtControl" */ './views/apps/ut/Control.vue')
      },
      {
        path: 'diagram',
        name: 'AppUTDiagram',
        component: () => import(/* webpackChunkName: "appUtDiagram" */ './views/apps/ut/Diagram.vue')
      },
      {
        path: 'editor',
        name: 'AppUTEditor',
        component: () => import(/* webpackChunkName: "appUtEditor" */ './views/apps/ut/Editor.vue')
      },
      {
        path: 'export',
        name: 'AppUTExport',
        component: () => import(/* webpackChunkName: "appUtexport" */ './views/apps/ut/Export.vue')
      },
      {
        path: 'presentation/:id?',
        name: 'AppUTPresentation',
        component: () => import(/* webpackChunkName: "appUtPresentation" */ './views/apps/ut/Presentation.vue')
      },
      {
        path: 'timelines',
        name: 'AppUTTimeline',
        component: () => import(/* webpackChunkName: "appUtTimeline" */ './views/apps/ut/Timeline.vue')
      }
    ]
  },
  {
    path: '/articles/list',
    name: 'ArticlesList',
    component: () => import(/* webpackChunkName: "articleslist" */ './views/ArticlesList.vue')
  },
  {
    path: '/articles/report',
    name: 'ArticlesReport',
    component: () => import(/* webpackChunkName: "articlesreport" */ './views/ArticlesReport.vue')
  },
  {
    path: '/assetclasses',
    name: 'Assetclasses',
    component: () => import(/* webpackChunkName: "assetclasses" */ './views/Assetclasses.vue')
  },
  {
    path: '/assurance',
    name: 'Assurance',
    component: () => import(/* webpackChunkName: "assurance" */ './views/Assurance.vue')
  },
  {
    path: '/auth/:action?',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/case/:id',
    name: 'Case',
    component: () => import(/* webpackChunkName: "case" */ './views/cases/Case.vue')
  },
  {
    path: '/cases',
    name: 'CasesLayout',
    component: () => import(/* webpackChunkName: "cases" */ './views/cases/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Cases',
        component: () => import(/* webpackChunkName: "appReportingLoading" */ './views/cases/Cases.vue')
      },
      {
        path: 'datastrat',
        name: 'CasesDataStrats',
        component: () => import(/* webpackChunkName: "appReportingMain" */ './views/cases/DataStrats.vue')
      }
    ]
  },
  {
    path: '/comm/add',
    name: 'CommAdd',
    component: () => import(/* webpackChunkName: "commadd" */ './views/CommAdd.vue')
  },
  {
    path: '/comm/edit/:id',
    name: 'CommEdit',
    component: () => import(/* webpackChunkName: "commedit" */ './views/CommEdit.vue')
  },
  {
    path: '/comm/:id',
    name: 'Comm',
    component: () => import(/* webpackChunkName: "comm" */ './views/Comm.vue')
  },
  {
    path: '/comms',
    name: 'Comms',
    component: () => import(/* webpackChunkName: "comms" */ './views/Comms.vue')
  },
  {
    path: '/commodities',
    component: () => import(/* webpackChunkName: "commoditieslayout" */ './views/commodities/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Commodities',
        component: () => import(/* webpackChunkName: "commodities" */ './views/commodities/Home.vue')
      },
      {
        path: 'initiative/:id',
        name: 'CommoditiesInitiative',
        component: () => import(/* webpackChunkName: "control" */ './views/commodities/Initiative.vue')
      },
      {
        path: 'initiatives',
        name: 'CommoditiesInitiatives',
        component: () => import(/* webpackChunkName: "commoditiesinitiatives" */ './views/commodities/Initiatives.vue')
      },
      {
        path: 'matrix',
        name: 'Commodities',
        component: () => import(/* webpackChunkName: "controlsmatrix" */ './views/controls/Matrix.vue')
      },
      {
        path: 'datastrats',
        name: 'CommoditiesDataStrats',
        component: () => import(/* webpackChunkName: "controlsdatastrats" */ './views/commodities/DataStrats.vue')
      },
      {
        path: 'insight/:id',
        name: 'CommoditiesInsight',
        component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
      },
      {
        path: 'insights',
        name: 'CommoditiesInsights',
        component: () => import(/* webpackChunkName: "controlsinsights" */ './views/commodities/Insights.vue')
      },
      {
        path: 'news',
        name: 'CommoditiesNews',
        component: () => import(/* webpackChunkName: "controlsnews" */ './views/commodities/News.vue')
      },
      {
        path: 'news/:id',
        name: 'CommoditiesNewsItem',
        component: () => import(/* webpackChunkName: "newsitem" */ './views/NewsItem.vue')
      },
      {
        path: 'obligation/:id',
        name: 'CommoditiesObligation',
        component: () => import(/* webpackChunkName: "commoditiesobligations" */ './views/commodities/Obligation.vue')
      },
      {
        path: 'obligations',
        name: 'CommoditiesObligations',
        component: () => import(/* webpackChunkName: "commoditiesobligations" */ './views/commodities/Obligations.vue')
      }
    ]
  },
  {
    path: '/controls',
    component: () => import(/* webpackChunkName: "controlslayout" */ './views/controls/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Controls',
        component: () => import(/* webpackChunkName: "controls" */ './views/controls/Home.vue')
      },
      {
        path: '/control/:id',
        name: 'Control',
        component: () => import(/* webpackChunkName: "control" */ './views/controls/Control.vue')
      },
      {
        path: 'controls',
        name: 'ControlsControls',
        component: () => import(/* webpackChunkName: "controlscontrols" */ './views/controls/Controls.vue')
      },
      {
        path: 'matrix',
        name: 'ControlsMatrix',
        component: () => import(/* webpackChunkName: "controlsmatrix" */ './views/controls/Matrix.vue')
      },
      {
        path: 'datastrats',
        name: 'ControlsDataStrats',
        component: () => import(/* webpackChunkName: "controlsdatastrats" */ './views/controls/DataStrats.vue')
      },
      {
        path: 'insight/:id',
        name: 'ControlsInsight',
        component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
      },
      {
        path: 'insights',
        name: 'ControlsInsights',
        component: () => import(/* webpackChunkName: "controlsinsights" */ './views/controls/Insights.vue')
      },
      {
        path: 'news',
        name: 'ControlsNews',
        component: () => import(/* webpackChunkName: "controlsnews" */ './views/controls/News.vue')
      },
      {
        path: 'news/:id',
        name: 'ControlsNewsItem',
        component: () => import(/* webpackChunkName: "newsitem" */ './views/NewsItem.vue')
      }
    ]
  },
  {
    path: '/controls-old/about',
    name: 'ControlAboutOld',
    component: () => import(/* webpackChunkName: "controlabout" */ './views/controls-old/About.vue')
  },
  {
    path: '/controls-old/control/:id',
    name: 'ControlOld',
    component: () => import(/* webpackChunkName: "control" */ './views/controls-old/Control.vue')
  },
  {
    path: '/controls-old/controls',
    name: 'ControlsOld',
    component: () => import(/* webpackChunkName: "controls" */ './views/controls-old/Controls.vue')
  },
  {
    path: '/controls-old/risks',
    name: 'ControlRisksOld',
    component: () => import(/* webpackChunkName: "controlrisks" */ './views/controls-old/Risks.vue')
  },
  {
    path: '/controls-old/statuses',
    name: 'ControlStatusesOld',
    component: () => import(/* webpackChunkName: "controlstatuses" */ './views/controls-old/Statuses.vue')
  },
  {
    path: '/controls-old/themes',
    name: 'ControlThemesOld',
    component: () => import(/* webpackChunkName: "controlthemes" */ './views/controls-old/Themes.vue')
  },
  {
    path: '/coverage',
    name: 'Coverage',
    component: () => import(/* webpackChunkName: "coverage" */ './views/Coverage.vue')
  },
  {
    path: '/doc/:id',
    name: 'Doc',
    component: () => import(/* webpackChunkName: "doc" */ './views/docs/Doc.vue')
  },
  {
    path: '/docs',
    name: 'Docs',
    component: () => import(/* webpackChunkName: "docs" */ './views/docs/Docs.vue')
  },
  {
    path: '/riskcomponent/:id',
    name: 'Riskcomponent',
    component: () => import(/* webpackChunkName: "riskcomponent" */ './views/decomp/Riskcomponent.vue')
  },
  {
    path: '/riskcomponents',
    name: 'Riskcomponents',
    component: () => import(/* webpackChunkName: "riskcomponents" */ './views/decomp/Riskcomponents.vue')
  },
  {
    path: '/extsource/add',
    name: 'ExtsourceAdd',
    component: () => import(/* webpackChunkName: "extsourceadd" */ './views/ExtsourceAdd.vue')
  },
  {
    path: '/extsource/edit/:id',
    name: 'ExtsourceEdit',
    component: () => import(/* webpackChunkName: "extsourceedit" */ './views/ExtsourceEdit.vue')
  },
  {
    path: '/event/add',
    name: 'EventAdd',
    component: () => import(/* webpackChunkName: "eventadd" */ './views/EventAdd.vue')
  },
  {
    path: '/event/edit/:id',
    name: 'EventEdit',
    component: () => import(/* webpackChunkName: "eventedit" */ './views/EventEdit.vue')
  },
  {
    path: '/experts',
    name: 'ExpertsLayout',
    component: () => import(/* webpackChunkName: "expertslaeyout" */ './views/experts/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Experts',
        component: () => import(/* webpackChunkName: "experts" */ './views/experts/Experts.vue')
      },
      {
        path: 'expert/:id',
        name: 'Expert',
        component: () => import(/* webpackChunkName: "expert" */ './views/experts/Expert.vue')
      }
    ]
  },
  {
    path: '/faqs/about',
    name: 'FaqAbout',
    component: () => import(/* webpackChunkName: "faqabout" */ './views/faqs/About.vue')
  },
  {
    path: '/faqs/faq/:id',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ './views/faqs/Faq.vue')
  },
  {
    path: '/faqs/faqs',
    name: 'Faqs',
    component: () => import(/* webpackChunkName: "controls" */ './views/faqs/Faqs.vue')
  },
  {
    path: '/form/:model/edit/:id',
    name: 'FormEdit',
    component: () => import(/* webpackChunkName: "formedit" */ './views/standard/FormNew.vue')
  },
  {
    path: '/form/:model/new',
    name: 'FormNew',
    component: () => import(/* webpackChunkName: "formnew" */ './views/standard/FormNew.vue')
  },
  {
    path: '/getESG',
    name: 'getESG',
    component: () => import(/* webpackChunkName: "getesg" */ './views/getESG.vue')
  },
  {
    path: '/impact/about',
    name: 'ImpactAbout',
    component: () => import(/* webpackChunkName: "impactabout" */ './views/impact/About.vue')
  },
  {
    path: '/impact/expert/:id',
    name: 'ImpactExpert',
    component: () => import(/* webpackChunkName: "impactexpert" */ './views/impact/Expert.vue')
  },
  {
    path: '/impact/experts',
    name: 'ImpactExperts',
    component: () => import(/* webpackChunkName: "impactexperts" */ './views/impact/Experts.vue')
  },
  {
    path: '/impact/obligation/:id',
    name: 'ImpactObligation',
    component: () => import(/* webpackChunkName: "impactobligation" */ './views/impact/Obligation.vue')
  },
  {
    path: '/impact/policy/:id',
    name: 'ImpactPolicy',
    component: () => import(/* webpackChunkName: "impactpolicy" */ './views/impact/Policy.vue')
  },
  {
    path: '/impact/reporting',
    name: 'ImpactReporting',
    component: () => import(/* webpackChunkName: "impactreporting" */ './views/impact/Reporting.vue')
  },
  {
    path: '/impact/reportingmi',
    name: 'ImpactStats',
    component: () => import(/* webpackChunkName: "impactstats" */ './views/impact/ImpactReport.vue')
  },
  {
    path: '/impact/topic/:id',
    name: 'ImpactTopic',
    component: () => import(/* webpackChunkName: "impacttopic" */ './views/impact/Topic.vue')
  },
  {
    path: '/impact/topics',
    name: 'ImpactTopics',
    component: () => import(/* webpackChunkName: "impacttopics" */ './views/impact/Topics.vue')
  },
  {
    path: '/impactstatus',
    name: 'Impactstatus',
    component: () => import(/* webpackChunkName: "impactstatus" */ './views/Impactstatus.vue')
  },
  {
    path: '/keywords',
    component: () => import(/* webpackChunkName: "keywords" */ './views/keywords/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Keywords',
        component: () => import(/* webpackChunkName: "keywordshome" */ './views/keywords/Home.vue')
      },
      {
        path: '/keyword/:keyword',
        name: 'Keyword',
        component: () => import(/* webpackChunkName: "newskeyword" */ './views/keywords/Keyword.vue')
      }
    ]
  },
  {
    path: '/list/:model',
    name: 'List',
    component: () => import(/* webpackChunkName: "list" */ './views/List.vue')
  },
  {
    path: '/listsimple/:model',
    name: 'ListSimple',
    component: () => import(/* webpackChunkName: "listsimple" */ './views/standard/ListSimple.vue')
  },
  {
    path: '/logincognitoauth/:action?',
    name: 'LoginCognitoAuth',
    component: () => import(/* webpackChunkName: "list" */ './views/LoginCognitoAuth.vue')
  },
  {
    path: '/metrics',
    component: () => import(/* webpackChunkName: "metrics" */ './views/metrics/Layout.vue'),
    children: [
      {
        path: '',
        name: 'MetricsHome',
        component: () => import(/* webpackChunkName: "metricshome" */ './views/metrics/Home.vue')
      },
      {
        path: 'download',
        name: 'MetricsDownload',
        component: () => import(/* webpackChunkName: "metricsdownload" */ './views/metrics/Download.vue')
      },
      {
        path: 'interoperability',
        name: 'Interoperability',
        component: () => import(/* webpackChunkName: "interoperability" */ './views/metrics/Interoperability.vue')
      },
      {
        path: 'lineage',
        component: () => import(/* webpackChunkName: "lineagelayout" */ './views/metrics/LineageLayout.vue'),
        children: [
          {
            path: '',
            name: 'Lineage',
            component: () => import(/* webpackChunkName: "lineagetree" */ './views/metrics/lineage/Tree.vue')
          },
          {
            path: 'table',
            name: 'LineageTable',
            component: () => import(/* webpackChunkName: "lineagetable" */ './views/metrics/lineage/Table.vue')
          },
          {
            path: 'sankey',
            name: 'LineageSankey',
            component: () => import(/* webpackChunkName: "lineagesankey" */ './views/metrics/lineage/Sankey.vue')
          }
        ]
      },
      {
        path: 'metrics',
        name: 'Metrics',
        component: () => import(/* webpackChunkName: "metrics" */ './views/metrics/Metrics.vue')
      },
      {
        path: 'dataelements',
        name: 'DataElements',
        component: () => import(/* webpackChunkName: "dataelements" */ './views/metrics/DataElements.vue')
      },
      {
        path: 'dataelement/:id',
        name: 'DataElement',
        component: () => import(/* webpackChunkName: "dataelement" */ './views/metrics/DataElement.vue')
      },
      {
        path: 'data-model',
        name: 'DataModel',
        component: () => import(/* webpackChunkName: "datamodel" */ './views/metrics/DataModel.vue')
      },
      {
        path: 'datastrats',
        name: 'MetricsDataStrats',
        component: () => import(/* webpackChunkName: "taxdatastrats" */ './views/metrics/DataStrats.vue')
      },
      {
        path: 'graphs',
        name: 'MetricsGraphs',
        component: () => import(/* webpackChunkName: "metricsgraphs" */ './views/metrics/Graphs.vue')
      },
      {
        path: 'metric/:id',
        name: 'Metric',
        component: () => import(/* webpackChunkName: "metric" */ './views/metrics/Metric.vue')
      },
      {
        path: 'reportingframework/:id',
        name: 'MetricsReportingframework',
        component: () => import(/* webpackChunkName: "reportingframework" */ './views/metrics/Reportingframework.vue')
      },
      {
        path: 'reportingframeworks',
        name: 'MetricsReportingframeworks',
        component: () => import(/* webpackChunkName: "reportingframeworks" */ './views/metrics/Reportingframeworks.vue')
      }
    ]
  },
  {
    path: '/migrations',
    name: 'Migrations',
    component: () => import(/* webpackChunkName: "migrations" */ './views/Migrations.vue')
  },
  {
    path: '/news/aitag/:id',
    name: 'NewsAiTag',
    component: () => import(/* webpackChunkName: "newsaitag" */ './views/NewsAiTag.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "notifications" */ './views/Notifications.vue')
  },
  {
    path: '/notificationsexternal',
    name: 'NotificationsExternal',
    component: () => import(/* webpackChunkName: "notificationsexternal" */ './views/NotificationsExternal.vue')
  },
  {
    path: '/person/:id',
    name: 'Person',
    component: () => import(/* webpackChunkName: "person" */ './views/Person.vue')
  },
  {
    path: '/persons',
    name: 'Persons',
    component: () => import(/* webpackChunkName: "persons" */ './views/Persons.vue')
  },
  {
    path: '/persontypes',
    name: 'Persontypes',
    component: () => import(/* webpackChunkName: "persontypes" */ './views/Persontypes.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ './views/Projects.vue')
  },
  {
    path: '/ptt',
    name: 'Ptt',
    component: () => import(/* webpackChunkName: "ptt" */ './views/Ptt.vue')
  },
  {
    path: '/publication/add',
    name: 'PublicationAdd',
    component: () => import(/* webpackChunkName: "publicationadd" */ './views/PublicationAdd.vue')
  },
  {
    path: '/processes',
    component: () => import(/* webpackChunkName: "processeslayout" */ './views/processes/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Processes',
        component: () => import(/* webpackChunkName: "processes" */ './views/processes/Processes.vue')
      },
      {
        path: 'datastrat',
        name: 'ProcessesDataStrats',
        component: () => import(/* webpackChunkName: "processesdatastrats" */ './views/processes/DataStrats.vue')
      },
      {
        path: 'process/:id',
        name: 'Process',
        component: () => import(/* webpackChunkName: "processesdatastrats" */ './views/processes/Process.vue')
      }
    ]
  },
  {
    path: '/producttypes',
    name: 'Producttypes',
    component: () => import(/* webpackChunkName: "producttypes" */ './views/Producttypes.vue')
  },
  {
    path: '/regdecomp/about',
    name: 'RegDecompAbout',
    component: () => import(/* webpackChunkName: "RegDecompAbout" */ './views/decomp/About.vue')
  },
  {
    path: '/regdecomp/entities',
    name: 'RegDecompEntities',
    component: () => import(/* webpackChunkName: "RegDecompEntities" */ './views/decomp/Entities.vue')
  },
  {
    path: '/regdecomp/functions',
    name: 'RegDecompFunctions',
    component: () => import(/* webpackChunkName: "RegDecompFunctions" */ './views/decomp/Functions.vue')
  },
  {
    path: '/regdecomp/journal',
    name: 'RegDecompJournal',
    component: () => import(/* webpackChunkName: "RegDecompJournal" */ './views/decomp/Journal.vue')
  },
  {
    path: '/regdecomp/obligation/:id',
    name: 'RegDecompObligation',
    component: () => import(/* webpackChunkName: "regDecompObligations" */ './views/decomp/Obligation.vue')
  },
  {
    path: '/regdecomp/obligations',
    name: 'RegDecompObligations',
    component: () => import(/* webpackChunkName: "regDecompObligations" */ './views/decomp/Obligations.vue')
  },
  {
    path: '/regdecomp/pdf',
    name: 'RegDecompPDF',
    component: () => import(/* webpackChunkName: "RegDecompPDF" */ './views/decomp/PDF.vue')
  },
  {
    path: '/regdecomp/regulations',
    name: 'RegDecompRegulations',
    component: () => import(/* webpackChunkName: "RegDecompRegulations" */ './views/decomp/Regulations.vue')
  },
  {
    path: '/regdecomp/regulation/:id',
    name: 'RegDecompRegulation',
    component: () => import(/* webpackChunkName: "RegDecompRegulations" */ './views/decomp/Regulation.vue')
  },
  {
    path: '/regdecomp/requirements',
    name: 'RegDecompRequirements',
    component: () => import(/* webpackChunkName: "RegDecompRequirements" */ './views/decomp/Requirements.vue')
  },
  {
    path: '/regdecomp/static',
    name: 'RegDecompStatic',
    component: () => import(/* webpackChunkName: "RegDecompStatic" */ './views/decomp/Static.vue')
  },
  {
    path: '/regdecomp/workspace/:id',
    name: 'RegDecompWorkspace',
    component: () => import(/* webpackChunkName: "regDecompWorkspace" */ './views/decomp/Workspace.vue')
  },
  {
    path: '/regbite/add',
    name: 'RegbiteAdd',
    component: () => import(/* webpackChunkName: "regbiteadd" */ './views/RegbiteAdd.vue')
  },
  {
    path: '/regbite/edit/:id',
    name: 'RegbiteEdit',
    component: () => import(/* webpackChunkName: "regbiteedit" */ './views/RegbiteEdit.vue')
  },
  {
    path: '/regtopicgroups',
    name: 'Regtopicgroups',
    component: () => import(/* webpackChunkName: "regtopicgroups" */ './views/Regtopicgroups.vue')
  },
  {
    path: '/regulator/add',
    name: 'RegulatorAdd',
    component: () => import(/* webpackChunkName: "regulatoradd" */ './views/RegulatorAdd.vue')
  },
  {
    path: '/regulator/edit/:id',
    name: 'RegulatorEdit',
    component: () => import(/* webpackChunkName: "regulatoredit" */ './views/RegulatorEdit.vue')
  },
  {
    path: '/relationships',
    name: 'Relationships',
    component: () => import(/* webpackChunkName: "relationships" */ './views/Relationships.vue')
  },
  {
    path: '/reportingframework',
    name: 'Reportingframework',
    component: () => import(/* webpackChunkName: "reportingframework" */ './views/Reportingframework.vue')
  },
  {
    path: '/reportingfrequency',
    name: 'Reportingfrequency',
    component: () => import(/* webpackChunkName: "reportingfrequency" */ './views/Reportingfrequency.vue')
  },
  {
    path: '/reportingmi',
    name: 'ReportingmiLayout',
    component: () => import(/* webpackChunkName: "reportingmilayout" */ './views/reportingmi/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Reportingmi',
        component: () => import(/* webpackChunkName: "reportingmi" */ './views/reportingmi/Items.vue')
      },
      {
        path: 'emails',
        name: 'ReportingmiEmails',
        component: () => import(/* webpackChunkName: "reportingmiemails" */ './views/reportingmi/Emails.vue')
      },
      {
        path: 'datastrat',
        name: 'ReportingmiDataStrats',
        component: () => import(/* webpackChunkName: "reportingmidatastrats" */ './views/reportingmi/DataStrats.vue')
      },
      {
        path: 'item/:id',
        name: 'ReportingmiItem',
        component: () => import(/* webpackChunkName: "reportingmiitem" */ './views/reportingmi/Item.vue')
      }
    ]
  },
  {
    path: '/riclists',
    name: 'Riclists',
    component: () => import(/* webpackChunkName: "riclists" */ './views/riclists/Riclists.vue')
  },
  {
    path: '/riclist/:id',
    name: 'Riclist',
    component: () => import(/* webpackChunkName: "riclist" */ './views/riclists/Riclist.vue')
  },
  {
    path: '/risk/add',
    name: 'RiskAdd',
    component: () => import(/* webpackChunkName: "riskadd" */ './views/RiskAdd.vue')
  },
  {
    path: '/risk/edit/:id',
    name: 'RiskEdit',
    component: () => import(/* webpackChunkName: "riskedit" */ './views/RiskEdit.vue')
  },
  {
    path: '/rricadmin',
    name: 'RRICAdmin',
    component: () => import(/* webpackChunkName: "rricadmin" */ './views/RRICAdmin.vue')
  },
  {
    path: '/sanctions',
    name: 'SanctionsLayout',
    component: () => import(/* webpackChunkName: "sanctionsleyout" */ './views/sanctions/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Sanctions',
        component: () => import(/* webpackChunkName: "sanctions" */ './views/sanctions/Sanctions.vue')
      },
      {
        path: 'datastrat',
        name: 'SanctionsDataStrats',
        component: () => import(/* webpackChunkName: "sanctionsdatastrats" */ './views/sanctions/DataStrats.vue')
      },
      {
        path: 'sanction/:id',
        name: 'Sanction',
        component: () => import(/* webpackChunkName: "sanctionsdatastrats" */ './views/sanctions/Sanction.vue')
      }
    ]
  },
  {
    path: '/source/add',
    name: 'SourceAdd',
    component: () => import(/* webpackChunkName: "sourceadd" */ './views/SourceAdd.vue')
  },
  {
    path: '/source/:id',
    name: 'Source',
    component: () => import(/* webpackChunkName: "source" */ './views/Source.vue')
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import(/* webpackChunkName: "stats" */ './views/Stats.vue')
  },
  {
    path: '/suggestrule/:id',
    name: 'Suggestrule',
    component: () => import(/* webpackChunkName: "suggestrule" */ './views/suggestrules/Rule.vue')
  },
  {
    path: '/suggestrules',
    name: 'Suggestrules',
    component: () => import(/* webpackChunkName: "suggestrules" */ './views/suggestrules/Rules.vue')
  },
  {
    path: '/signoutcognitoauth',
    name: 'SignOutCognitoAuth',
    component: () => import(/* webpackChunkName: "stats" */ './views/SignOutCognitoAuth.vue')
  },
  {
    path: '/tag/:id',
    name: 'Tag',
    component: () => import(/* webpackChunkName: "tag" */ './views/Tag.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import(/* webpackChunkName: "tags" */ './views/Tags.vue')
  },
  {
    path: '/tax',
    component: () => import(/* webpackChunkName: "tahlayout" */ './views/tax/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Tax',
        component: () => import(/* webpackChunkName: "tax" */ './views/tax/Home.vue')
      },
      {
        path: 'country/:country',
        name: 'TaxCountry',
        component: () => import(/* webpackChunkName: "taxcountry" */ './views/tax/Country.vue')
      },
      {
        path: 'datastrats',
        name: 'TaxDataStrats',
        component: () => import(/* webpackChunkName: "taxdatastrats" */ './views/tax/DataStrats.vue')
      },
      {
        path: 'initiative/:id',
        name: 'TaxInitiative',
        component: () => import(/* webpackChunkName: "taxinitiative" */ './views/impact/Initiative.vue')
      },
      {
        path: '/insight/:id',
        name: 'TaxInsight',
        component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
      },
      {
        path: 'insights',
        name: 'TaxInsights',
        component: () => import(/* webpackChunkName: "taxinsights" */ './views/tax/Insights.vue')
      },
      {
        path: 'map',
        name: 'TaxMap',
        component: () => import(/* webpackChunkName: "taxmap" */ './views/tax/Map.vue')
      },
      {
        path: 'news',
        name: 'TaxNews',
        component: () => import(/* webpackChunkName: "taxnews" */ './views/tax/News.vue')
      },
      {
        path: 'records',
        name: 'TaxRegulations',
        component: () => import(/* webpackChunkName: "taxrecords" */ './views/tax/Records.vue')
      }
    ]
  },
  {
    path: '/taxonomy',
    component: () => import(/* webpackChunkName: "taxonomylayout" */ './views/taxonomy/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Taxonomy',
        component: () => import(/* webpackChunkName: "taxonomy" */ './views/taxonomy/Taxonomy.vue')
      },
      {
        path: 'item/:id',
        name: 'TaxonomyItem',
        component: () => import(/* webpackChunkName: "taxonomyitem" */ './views/taxonomy/Item.vue')
      }
    ]
  },
  {
    path: '/topic/add',
    name: 'TopicAdd',
    component: () => import(/* webpackChunkName: "topicadd" */ './views/TopicAdd.vue')
  },
  {
    path: '/topic/edit/:id',
    name: 'TopicEdit',
    component: () => import(/* webpackChunkName: "topicedit" */ './views/TopicEdit.vue')
  },
  {
    path: '/trainingcases',
    name: 'TrainingcasesLayout',
    component: () => import(/* webpackChunkName: "trainingcaseslayout" */ './views/trainingcases/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Trainingcases',
        component: () => import(/* webpackChunkName: "trainingcases" */ './views/trainingcases/Inventory.vue')
      },
      {
        path: 'datastrat',
        name: 'TrainingcasesDataStrats',
        component: () => import(/* webpackChunkName: "trainingcasesdatastrats" */ './views/trainingcases/DataStrats.vue')
      },
      {
        path: 'player',
        name: 'TrainingcasesPlayer',
        component: () => import(/* webpackChunkName: "trainingcasesplayer" */ './views/trainingcases/Player.vue')
      },
      {
        path: 'trainingcase/:id',
        name: 'Trainingcase',
        component: () => import(/* webpackChunkName: "trainingcasesdatastrats" */ './views/trainingcases/Trainingcase.vue')
      }
    ]
  },
  {
    path: '/ugroups',
    name: 'UGroups',
    component: () => import(/* webpackChunkName: "ugroups" */ './views/UGroups.vue')
  },
  {
    path: '/usergroups',
    name: 'UserGroups',
    component: () => import(/* webpackChunkName: "usergroups" */ './views/UserGroups.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ './views/Users.vue')
  },
  {
    path: '/workflow',
    name: 'Workflow',
    component: () => import(/* webpackChunkName: "workflow" */ './views/Workflow.vue')
  },
  {
    path: '/workflow/articles',
    name: 'WorkflowArticles',
    component: () => import(/* webpackChunkName: "workflowarticles" */ './components/workflow/Articles.vue')
  },
  {
    path: '/workflow/articles/dashboard',
    name: 'WorkflowArticlesDashboard',
    component: () => import(/* webpackChunkName: "workflowarticlesdashboard" */ './components/workflow/article/Dashboard.vue')
  },
  {
    path: '/workflow/articles/diagram',
    name: 'WorkflowArticlesDiagram',
    component: () => import(/* webpackChunkName: "workflowarticlesdiagram" */ './components/workflow/article/Diagram.vue')
  },
  {
    path: '/workflow/articles/deleted',
    name: 'WorkflowArticlesDeleted',
    component: () => import(/* webpackChunkName: "workflowarticlesdeleted" */ './components/workflow/article/Deleted.vue')
  },
  {
    path: '/workflow/articles/journal',
    name: 'WorkflowArticlesJournal',
    component: () => import(/* webpackChunkName: "workflowarticlesjournal" */ './components/workflow/article/Journal.vue')
  },
  {
    path: '/workflow/articles/personal',
    name: 'WorkflowArticlesPersonal',
    component: () => import(/* webpackChunkName: "workflowarticlespersonal" */ './components/workflow/article/Personal.vue')
  },
  {
    path: '/workflow/articles/published',
    name: 'WorkflowArticlesPublished',
    component: () => import(/* webpackChunkName: "workflowarticlespublished" */ './components/workflow/article/Published.vue')
  },
  {
    path: '/workflow/articles/starrednews',
    name: 'WorkflowArticlesStarredNews',
    component: () => import(/* webpackChunkName: "workflowarticlespersonal" */ './components/workflow/article/StarredNews.vue')
  },
  {
    path: '/workflow/article/:id',
    name: 'WorkflowArticle',
    component: () => import(/* webpackChunkName: "workflowarticle" */ './components/workflow/Article.vue')
  },
  {
    path: '/worldmap',
    name: 'Worldmap',
    component: () => import(/* webpackChunkName: "worldmap" */ './views/WorldMap.vue')
  }
]

let routes = routesBase

if (config.BUILD === 'full') {
  routes = routes.concat(routesInternal)
}

if (config.BUILD === 'external') {
  routes = routes.concat(routesExternal)
}

if (config.BUILD === 'externalkpmgarc') {
  routes = routes.concat(routesExternal)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: routes
})
